<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        :to="{ name: 'auth-login' }"
      >
        <!--        <vuexy-logo />-->
        <h2 class="brand-text text-primary ml-1">
          {{ $t('managementPortal') }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            :alt="$t('loginV2')"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('niceToSeeYou') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('logInAndLetsGo') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                :label="$t('emailAddress')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                    autocomplete="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('password') }}</label>
                  <!--                  <b-link :to="{name:'auth-forgot-password'}">-->
                  <!--                    <small>Forgot Password?</small>-->
                  <!--                  </b-link>-->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                      autocomplete="current-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!--              <b-form-group>-->
              <!--                <b-form-checkbox-->
              <!--                  id="remember-me"-->
              <!--                  v-model="status"-->
              <!--                  name="checkbox-1"-->
              <!--                >-->
              <!--                  Remember Me-->
              <!--                </b-form-checkbox>-->
              <!--              </b-form-group>-->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                {{ $t('login') }}
              </b-button>
              <!--              <b-button-->
              <!--                type="submit"-->
              <!--                variant="secondary"-->
              <!--                block-->
              <!--                @click="validationFormSpoofed"-->
              <!--              >-->
              <!--                Demo Login-->
              <!--              </b-button>-->
            </b-form>
          </validation-observer>

          <!--          <b-card-text class="text-center mt-2">-->
          <!--            <span>New on our platform? </span>-->
          <!--            <b-link :to="{name:'auth-register'}">-->
          <!--              <span>&nbsp;Create an account</span>-->
          <!--            </b-link>-->
          <!--          </b-card-text>-->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import md5 from 'md5'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      // sideImg: require('@/assets/images/pages/login-v2.svg'),
      sideImg: require('@/assets/images/pages/emma-logo.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail.toLowerCase(),
            password: md5(this.password),
          }).then(response => {
            useJwt.setToken(response.data.token)
            const userData = {
              id: response.data.user.id,
              Organizations: response.data.user.Organizations,
              fullName: response.data.user.name,
              username: response.data.user.name,
              avatar: '/img/1-small.png',
              email: response.data.user.email,
              ability: [
                {
                  action: 'manage',
                  subject: 'all',
                },
              ],
            }
            if (response.data.user.access === 'Superuser') {
              userData.role = 'admin'
            } else {
              userData.role = 'organizationadmin'
            }
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$ability.update(userData.ability)

            this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Willkommen ${userData.fullName || userData.username}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Du hast dich erfolgreich eingeloggt!',
                  },
                })
              })
          })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Anmeldung fehlgeschlagen',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: 'Bitte überprüfe deine Email Adresse und dein Passwort',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
